import React from 'react';
import './About.css';

function About() {
  return (
    <section className="about">
      <div className="container">
        <h2>About Us</h2>
        <p>
          carrom.pro unites carrom enthusiasts from around the globe, creating a vibrant and connected community.
          Here, players of all skill levels can connect, compete, and share their love for this timeless game. 
          Our platform offers resources, tournaments, and a chance to engage with fellow carrom lovers, fostering friendships and rivalries alike. 
          Join us as we celebrate the spirit of carrom and elevate the game to new heights!
        </p>
      </div>
    </section>
  );
}

export default About;
