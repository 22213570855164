import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import carromLogo from '../assets/carrom-logo.png'; // Import the logo

function Navbar() {
  const [isActive, setIsActive] = useState(false);
  const navRef = useRef(null);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  // Function to close the menu when clicking outside
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar" ref={navRef}>
      <div className="container">
        <Link to="/" className="logo">
          <img src={carromLogo} alt="Carrom Pro Logo" />
        </Link>
        <div className="menu-toggle" onClick={toggleMenu}>
          ☰ {/* Icon or text for the menu toggle */}
        </div>
        <ul className={isActive ? 'active' : ''}>
          <li><Link to="/" onClick={() => setIsActive(false)}>Home</Link></li>
          <li><Link to="/circuit" onClick={() => setIsActive(false)}>Circuit</Link></li>
          {/* <li><Link to="/community" onClick={() => setIsActive(false)}>Community</Link></li>
          <li><Link to="/about" onClick={() => setIsActive(false)}>About</Link></li> */}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
