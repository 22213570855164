import React from 'react';
import './Offerings.css';
import circuitImage from '../assets/circuit1.png';
import communityImage from '../assets/community.png';
import profileImage from '../assets/profile.png';

function Offerings() {
  const offerings = [
    {
      title: 'Professional Carrom Circuit',
      description: `Step into the spotlight with our Professional Carrom Circuit, designed for players who are eager to compete and shine on a global stage. 
                    This platform brings together the best talents in the carrom community, allowing you to participate in thrilling tournaments that test your skills and strategies. 
                    With a detailed tournament schedule, registered players list, and real-time updates, you’ll always be in the loop. 
                    Whether you're a seasoned player or a rising star, the circuit offers an unparalleled opportunity to connect, compete, and celebrate the game you love.`,
      image: circuitImage,
    },
    {
      title: 'Community Engagement',
      description: `Become a part of a vibrant and growing global community of carrom players dedicated to sharing their passion for the game. 
                    Our platform fosters connections among enthusiasts, allowing you to engage with players from various backgrounds and skill levels. 
                    Participate in discussions, share tips, and exchange experiences to enhance your game. We believe that every player has a story, 
                    and together we can create a rich tapestry of carrom culture. Join us in building lasting friendships and camaraderie through the love of carrom.`,
      image: communityImage,
    },
    {
      title: 'Player Profiles',
      description: `Elevate your carrom experience by creating a personalized player profile that highlights your skills, achievements, and journey in the game. 
                    This feature allows you to showcase your unique style and accomplishments to the community. Connect with other players, track your progress, 
                    and engage in friendly competition. Your profile serves as your digital footprint in the carrom world, making it easier for others to recognize your talent and dedication. 
                    Whether you're a beginner or a seasoned pro, your profile is a celebration of your love for carrom.`,
      image: profileImage,
    },
  ];

  return (
    <section className="offerings">
      <div className="container">
        <h2>Offerings</h2>
        <h3>Enjoy exciting services designed for players</h3>
        <div className="offerings-list">
          {offerings.map((offering, index) => (
            <div key={index} className="offering">
              <img src={offering.image} alt={offering.title} className="offering-image" />
              <div className="offering-content">
                <h4>{offering.title}</h4>
                <p>{offering.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Offerings;
