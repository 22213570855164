import React from 'react';
import { useParams } from 'react-router-dom';
import Results from './Results';
import './TournamentPage.css';

function TournamentPage({ tournaments }) {
  const { tournamentId } = useParams();
  const tournament = tournaments.find(t => t.id === parseInt(tournamentId));

  if (!tournament) {
    return <div className="tournament-page">Sorry, tournament not found!</div>;
  }

  const isPast = new Date(tournament.date) < new Date();
  const spotsRemaining = tournament.spotsTotal - tournament.spotsFilled;

  return (
    <div className="tournament-page">
      <h1>{tournament.name}</h1>
      <p className="tournament-date">{tournament.date}</p>
      {isPast ? (
        <Results tournament={tournament} />
      ) : (
        <div className="tournament-details">
          <h2>Register for the Tournament</h2>
          <p className="tournament-description">{tournament.details}</p>
          <p className="entry-info">Entries: {tournament.spotsFilled}/{tournament.spotsTotal} ({spotsRemaining} spots remaining)</p>
          {tournament.streamLink && (
            <p className="stream-link">
              <a href={tournament.streamLink} target="_blank" rel="noopener noreferrer">Watch the Event Live</a>
            </p>
          )}
          <p className="contact-info">
            Please contact us at <a href="mailto:circuit@carrom.pro">circuit@carrom.pro</a> with any questions.
          </p>
          <p className="registration-link">
            <a href={tournament.registrationLink} target="_blank" rel="noopener noreferrer" className="register-button">Click Here to Register</a>
          </p>
        </div>
      )}
    </div>
  );
}

export default TournamentPage;