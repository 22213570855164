//pages/Circuit.js

import React from 'react';
import './Circuit.css';
import circuitLogo from '../assets/carrom-circuit-logo.png';
import { Link } from 'react-router-dom';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }; // Set timeZone option to 'UTC'
  return date.toLocaleDateString('en-US', options);
}



function Circuit({ tournaments }) {
  return (
    <div className="circuit">
      <div className="logo-container">
        <img src={circuitLogo} alt="Carrom Pro Circuit Logo" className="circuit-logo" />
      </div>
      <div className="tournaments-container">
        <h2>Tournaments</h2>
        <div className="tournament-list-wrapper">
          <ul className="tournament-list">
            {tournaments.map(tournament => (
              <li key={tournament.id} className="tournament-item">
                <Link to={`/tournament/${tournament.id}`} className="tournament-link">
                  <h3>{tournament.name}</h3>
                </Link>
                <p>{formatDate(tournament.date)}</p>
                <button
                  className={`tournament-button ${tournament.status}`}
                  onClick={() => {
                    const url = tournament.status === 'register' ? tournament.registrationLink : `/tournament/${tournament.id}`;
                    window.open(url, '_blank');
                  }}
                >
                  {tournament.status === 'register' ? 'Register' : 'Results'}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="see-us-in-action">
        <h2>See Us in Action</h2>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/d7pL8GAFciU?si=AFSWIoZy9nOb5nwX"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Circuit;
