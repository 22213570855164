import React, { useState } from 'react';
import './Results.css';

const Match = ({ match, isFinal }) => {
  const [player1, player2] = match.players;
  const [scores1, scores2] = match.scores;
  const maxBoards = Math.max(scores1.length, scores2.length);
  const total1 = scores1.reduce((a, b) => typeof b === 'number' ? a + b : a, 0);
  const total2 = scores2.reduce((a, b) => typeof b === 'number' ? a + b : a, 0);

  return (
    <div className={`match ${isFinal ? 'final-match' : ''}`}>
      <table className="match-table">
        <thead>
          <tr>
            <th className="header player-name">Player</th>
            {[...Array(maxBoards)].map((_, index) => (
              <th key={index} className="header board-header">B{index + 1}</th>
            ))}
            <th className="header">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="player-name">{player1}</td>
            {scores1.map((score, index) => (
              <td key={index} className="score">{score === "NA" ? "-" : score}</td>
            ))}
            <td className="final-score">{total1}</td>
          </tr>
          <tr>
            <td className="player-name">{player2}</td>
            {scores2.map((score, index) => (
              <td key={index} className="score">{score === "NA" ? "-" : score}</td>
            ))}
            <td className="final-score">{total2}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ExpandableSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="expandable-section">
      <div className="section-header" onClick={toggleExpand}>
        <h3>{title}</h3>
        <span className={`expand-icon ${isExpanded ? 'expanded' : ''}`}>
          {isExpanded ? '▼' : '▶'}
        </span>
      </div>
      {isExpanded && <div className="section-content">{children}</div>}
    </div>
  );
};

const Results = ({ tournament }) => {
  return (
    <div className="results">
      {/* <h1>{tournament.name}</h1> */}
      {/* <p className="tournament-date">{tournament.date}</p> */}
      <h2 className="results-subtitle">Results</h2>
      <div className="winner-section">
        <h3>Winner</h3>
        <div className="winner">{tournament.winner}</div>
      </div>
      <ExpandableSection title="Participants">
        <div className="participants-grid">
          {tournament.participants.map((participant, index) => (
            <div key={index} className="participant">{participant}</div>
          ))}
        </div>
      </ExpandableSection>
      {tournament.matchResults && (
        <div className="match-results">
          <h3>Match Results</h3>
          {tournament.matchResults.map((round, roundIndex) => (
            <ExpandableSection key={roundIndex} title={round.name}>
              <div className={`matches-container ${round.name === 'Finals' ? 'finals-container' : ''}`}>
                {round.matches.map((match, matchIndex) => (
                  <Match key={matchIndex} match={match} isFinal={round.name === 'Finals'} />
                ))}
              </div>
            </ExpandableSection>
          ))}
        </div>
      )}
      {tournament.videos && (
        <div className="youtube-clips">
          <h3>Match Videos</h3>
          {tournament.videos.map((video, index) => (
            <div key={index} className="video-container">
              <div className="video-wrapper">
                <iframe
                  src={`https://www.youtube.com/embed/${video}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={`Match ${index + 1}`}
                ></iframe>
              </div>
              <a href={`https://www.youtube.com/watch?v=${video}`} target="_blank" rel="noopener noreferrer" className="youtube-link">
                Watch on YouTube
              </a>
            </div>
          ))}
        </div>      
      )}
    </div>
  );
};

export default Results;