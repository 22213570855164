import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="container">
        {/* <p>© 2024 carrom.pro - All Rights Reserved</p> */}
        <p>Contact us</p>
        <a className="mail" href="mailto:circuit@carrom.pro"> circuit@carrom.pro</a>
        <p>Find us</p>
        <ul className="social-links">
          <li><a href="https://www.youtube.com/@carrom.procircuit/featured" target="_blank" rel="noopener noreferrer">YouTube</a></li>
          <li><a href="https://www.instagram.com/carrom.procircuit?igsh=MTl2a3hkcmlqeGJycw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          <li><a href="https://www.tiktok.com/@carrom.procircuit" target="_blank" rel="noopener noreferrer">TikTok</a></li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
