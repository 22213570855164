import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Circuit from './pages/Circuit';
import TournamentPage from './components/TournamentPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

const tournaments = [
  {
    id: 1,
    name: 'carrom.pro circuit: Battle In Bedminster',
    date: 'September 11, 2024',
    status: 'results',
    registrationLink: 'https://forms.gle/cJ55pPxRqRsX2GjW8',
    streamLink: '',
    spotsFilled: 16,
    spotsTotal: 16,
    details: "Join the carrom.pro Circuit for Event 2 on September 11, 2024, 6pm  - 10pm, in Bedminster, New Jersey. Register as an individual participant for $35, or compete as both an individual and a team member for $50. If registering for team participation, ensure that your teammate also selects this option and that you both list each other's names on the registration form. If you do not have a teammate, that is okay, we will pair you with another player. Each participant will receive a complimentary t-shirt and dinner during the event.",
    participants: ['Ajay Arora', 'Atul Bhave', 'Veenitha Bokaria', 'Arun Kumar', 'Mohammed Rahman', "Vishal Karangutkar", "Lalit Chauhan", "Prasanna Thiru", "Mohit Shah", "Palani Sundaram", "Viral Parmar", "Mayuresh Bhoir", "Asim Malik", "Raghuveer Bethelli", "Arshad Sheikh", "Abul Shakeel"],
    videos: ['tfpQZlghyNU'],
    winner: "Ajay Arora",
    matchResults: [
      {
        name: 'Group A',
        matches: [
          {
            players: ['Palani Sundaram', 'Atul Bhave'],
            scores: [
              [5, 10, 0, 1],
              [0, 0, 7, 0]
            ]
          },
          {
            players: ['Ajay Arora', 'Prasanna Thiru'],
            scores: [
              [5, 7, 11],
              [0, 0, 0]
            ]
          },
          {
            players: ['Atul Bhave', 'Prasanna Thiru'],
            scores: [
              [0, 8, 2, 0],
              [5, 0, 0, 1]
            ]
          },
          {
            players: ['Ajay Arora', 'Atul Bhave'],
            scores: [
              [5, 5, 5],
              [0, 0, 0]
            ]
          },
          {
            players: ['Palani Sundaram', 'Prasanna Thiru'],
            scores: [
              [0, 0, 0],
              [5, 4, 3]
            ]
          }
        ]
      },
      {
        name: 'Group B',
        matches: [
          {
            players: ['Arshad Sheikh', 'Arun Kumar'],
            scores: [
              [7, 8, 0, 4],
              [0, 0, 4, 0]
            ]
          },
          {
            players: ['Arshad Sheikh', 'Lalit Chauhan'],
            scores: [
              [0, 0, 6, 5],
              [6, 5, 0, 0]
            ]
          },
          {
            players: ['Arun Kumar', 'Mohit Shah'],
            scores: [
              [5, 4, 11],
              [0, 0, 0]
            ]
          },
          {
            players: ['Arshad Sheikh', 'Mohit Shah'],
            scores: [
              [2, 0, 10, 5],
              [0, 1, 0, 0]
            ]
          },
          {
            players: ['Arun Kumar', 'Lalit Chauhan'],
            scores: [
              [0, 8, 0, 1],
              [4, 0, 3, 0]
            ]
          }
        ]
      },
      {
        name: 'Group C',
        matches: [
          {
            players: ['Viral Parmar', 'Vishal Karangutkar'],
            scores: [
              [1, 13, 0],
              [0, 0, 0]
            ]
          },
          {
            players: ['Raghuveer Bethelli', 'Veenitha Bokaria'],
            scores: [
              [6, 7, 8],
              [0, 0, 0]
            ]
          },
          {
            players: ['Viral Parmar', 'Raghuveer Bethelli'],
            scores: [
              [4, 4, 4, 1],
              [0, 0, 0, 0]
            ]
          },
          {
            players: ['Vishal Karangutkar', 'Veenitha Bokaria'],
            scores: [
              [9, 5, 10, 1],
              [0, 0, 0, 0]
            ]
          },
          {
            players: ['Viral Parmar', 'Veenitha Bokaria'],
            scores: [
              [10, 10, 5],
              [0, 0, 0]
            ]
          }
        ]
      },
      {
        name: 'Group D',
        matches: [
          {
            players: ['Mayuresh Bhoir', 'Abul Shakeel'],
            scores: [
              [5, 5, 10],
              [0, 0, 0]
            ]
          },
          {
            players: ['Mayuresh Bhoir', 'Asim Malik'],
            scores: [
              [9, 7, 1, 8],
              [0, 0, 0, 0]
            ]
          },
          {
            players: ['Abul Shakeel', 'Mohammed Rahman'],
            scores: [
              [5, 4, 0, 0],
              [0, 0, 10, 5]
            ]
          },
          {
            players: ['Mayuresh Bhoir', 'Mohammed Rahman'],
            scores: [
              [3, 3, 12],
              [0, 0, 0]
            ]
          },
          {
            players: ['Abul Shakeel', 'Asim Malik'],
            scores: [
              [4, 4, 4],
              [0, 0, 0]
            ]
          }
        ]
      },
      {
        name: 'Semi-Finals',
        matches: [
          {
            players: ['Mayuresh Bhoir', 'Arshad Sheikh'],
            scores: [
              [7, 0, 4, 7],
              [0, 4, 0, 0]
            ]
          },
          {
            players: ['Ajay Arora', 'Viral Parmar'],
            scores: [
              [0, 8, 4, "NA"],
              [4, 0, 0, "NA"]
            ]
          }
        ]
      },
      {
        name: 'Finals',
        matches: [
          {
            players: ['Mayuresh Bhoir', 'Ajay Arora'],
            scores: [
              [0, 0, 5, 0, 6, 2, 0, 0],
              [2, 2, 0, 8, 0, 0, 6, 7]
            ]
          }
        ]
      }
    ]
  },
  {
    id: 2,
    name: 'carrom.pro Circuit: Event 1',
    date: 'July 21, 2024',
    status: 'results',
    resultsLink: '/results/2',
    winner: "Ajay Arora",
    participants: ['Ajay Arora', 'Atul Bhave', 'Veenitha Bokaria', 'Arun Kumar', 'Mohammed Rehman'],
    videos: ['pQtihBvkjTQ', 'ADSgApEGbMo', 'PWhjxW8nY20', 'Yy4m5DlrZE0', 'd7pL8GAFciU','37OR1PNWKtM','uBJ4b1yzG4Q', '_WYBOjWE2j4', 'do54ra00i7U', '2R3RzXRyQ4E', 'ubX_GZU46UU', 'zKR0abbbD5s', 'jmLmw6k5Di8']
  }
];

function App() {
  return (
    <Router>
      <div className="page-wrapper">
        <Navbar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/circuit" element={<Circuit tournaments={tournaments} />} />
            <Route path="/tournament/:tournamentId" element={<TournamentPage tournaments={tournaments} />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;