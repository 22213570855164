import React from 'react';
import './Home.css';
import About from '../components/About';
import Offerings from '../components/Offerings';

function Home() {
  return (
    <div className="home">
      <section className="hero">
        <div className="container">
          <h1>Welcome to carrom.pro</h1>
          <p>The global community for carrom players.</p>
          <a href="#learn-more" className="cta-button">Learn More</a>
        </div>
      </section>
      <section id="learn-more" className="container">
        <div>
            <About />
            <Offerings />
        </div>
      </section>
    </div>
  );
}

export default Home;
